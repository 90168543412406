<template>
  <div
    class="wrap-tsume-practice-list h-100 overflow-hidden"
    :class="[
      {'overflow-hidden': isLoading},
      {'jungo-wrap-tsume-practice-list': $isJungo},
    ]"
  >
    <loading-wrapper v-if="isLoading" class="loading"></loading-wrapper>
    <BaseHeader
      v-if="$device.isMobile"
      :title="$t('做題')"
      :is-title-bold="$isJungo"
      :back-on-click-left="false"
      :has-red-dot="hasVerificationNotification"
      :left-icon="$isJungo ? '' : 'Menu'"
      :right-text="
        currentPlan.id === '30k-10k' || currentPlan.id === '30k-10k-v2'
          ? $t('切換難度')
          : ''
      "
      @on-click-left="onClickSidebar"
      @on-click-right="showDifficulty"
    ></BaseHeader>

    <div
      class="wrapper position-relative"
      :class="{'scroll-bar': $device.isMobile}"
    >
      <div
        v-if="tsumePracticeList?.length || !permission?.tsume"
        class="wrap-tsume-practice-difficulty p-4 pt-md-2 pb-md-4 pl-md-2 pr-md-1"
      >
        <div
          class="difficulty m-0 text-16 mb-2 mb-md-3 d-flex justify-content-between align-items-center"
        >
          <p class="m-0 text-16">
            {{ $t('難度') }}
            <span v-if="!$isJungo && selectedPracticeList?.title">
              ：【 {{ selectedPracticeList.title }} 】
            </span>
            <span v-else-if="!$isJungo">：- </span>
          </p>
          <b-button
            v-if="
              !$device.isMobile &&
              (currentPlan.id === '30k-10k' ||
                currentPlan.id === '30k-10k-v2') &&
              !$isJungo
            "
            class="btn-change-tsume-difficulty"
            variant="primary"
            size="sm"
            pill
            @click="isDifficultyListShow = true"
          >
            {{ $t('切換難度') }}
          </b-button>
        </div>
        <div class="wrap-rate d-flex align-items-center pr-md-1">
          <div class="progress-rate w-100 overflow-hidden">
            <div
              class="reach-rate text-14"
              :style="{width: difficultyProgressRate + '%'}"
            ></div>
          </div>
          <span class="rate-percent ml-2">{{ difficultyProgressRate }}%</span>
        </div>
      </div>
      <div
        class="tsume-practice-list ml-3 mt-3 m-md-0 p-md-2"
        :class="[
          device === 'ios' ? 'mr-3' : 'mr-2',
          {'scroll-bar': !$device.isMobile},
        ]"
      >
        <template v-if="tsumePracticeList?.length">
          <tsume-type-card
            v-for="(tsumeType, index) in selectedPracticeList.types"
            :key="index"
            :tsume-type="tsumeType"
            :is-selected="selectedTsumeType === tsumeType.type"
            @on-item-click="getTsumePractice"
          ></tsume-type-card
        ></template>
        <div v-if="!permission?.tsume" class="empty-placeholder">
          <EmptyPlaceholder
            icon="Kifu2"
            :text="
              $t('當前課程不開放{type}', {
                type: $t('做題'),
              })
            "
            :sub-text="$t('您可以切換其他課程或前往官網購買')"
          />
          <b-button
            variant="primary"
            size="md"
            class="mt-3 mx-auto d-block"
            @click="onClick2Web"
          >
            {{ $t('前往官網了解') }}
          </b-button>
        </div>
      </div>
      <b-button
        v-if="!$device.isMobile"
        class="btn-practice mt-3 font-weight-bold"
        variant="primary"
        size="lg"
        :disabled="!permission?.tsume"
        @click="getTsumePractice('startPracticeTsume')"
      >
        {{ $t('開始') }}
      </b-button>
    </div>
    <action-sheet
      v-if="isDifficultyListShow && $device.isMobile"
      ref="action-sheet"
      :title="$t('切換難度')"
      @close="closeDifficulty"
    >
      <div
        class="tsume-practice-difficulty-list pl-3 pt-3 pb-3 scroll-bar"
        :class="device === 'ios' ? 'pr-3' : 'pr-2'"
      >
        <difficulty-card
          v-for="(tsumeDifficulty, index) in tsumePracticeList"
          :key="tsumeDifficulty.rank"
          :difficulty-name="tsumeDifficulty.title"
          :button-type="index === selectedDifficulty ? 'pick' : 'unpick'"
          :is-lock="tsumeDifficulty.isLock"
          :rank="tsumeDifficulty.rank"
          @click.native="pickDifficulty(tsumeDifficulty.isLock, index)"
        ></difficulty-card>
      </div>
    </action-sheet>
    <modal-wrapper
      v-if="isDifficultyListShow && !$device.isMobile"
      :is-modal-wrapper-show="isDifficultyListShow"
      :is-show-transition="true"
      @close="closeDifficulty"
    >
      <div
        ref="tsumePracticeDifficultyList"
        class="tsume-practice-difficulty-list py-4 px-3 scroll-bar"
        :class="{active: isDifficultyListShow}"
      >
        <difficulty-card
          v-for="(tsumeDifficulty, index) in tsumePracticeList"
          :key="tsumeDifficulty.rank"
          :difficulty-name="tsumeDifficulty.title"
          :condition="tsumeDifficulty.openDay"
          :button-type="index === selectedDifficulty ? 'pick' : 'unpick'"
          :is-lock="tsumeDifficulty.isLock"
          :rank="tsumeDifficulty.rank"
          @click.native="pickDifficulty(tsumeDifficulty.isLock, index)"
        ></difficulty-card>
      </div>
    </modal-wrapper>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import ActionSheet from '@/components/Base/ActionSheet';
import DifficultyCard from '@/components/Practice/DifficultyCard';
import TsumeTypeCard from '@/components/Practice/TsumeTypeCard';
import rankList from '@/json/rank.json';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import BackEvent from '@/lib/base/backEvent.js';
import ModalWrapper from '@/components/Base/ModalWrapper.vue';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';
import {Browser} from '@capacitor/browser';
import delay from '@/lib/base/delay.js';
import {manifest} from '@/constant/env.js';

export default {
  name: 'TsumePracticeList',
  components: {
    BaseHeader,
    ActionSheet,
    DifficultyCard,
    TsumeTypeCard,
    LoadingWrapper,
    ModalWrapper,
    EmptyPlaceholder,
  },
  data() {
    return {
      rankList,
      passVerification: false,
      selectedDifficulty: 0,
      selectedTsumeType: '',
      isDifficultyListShow: false,
      isLoading: true,
    };
  },
  computed: {
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    difficultyProgressRate() {
      /* eslint-disable indent */
      return this.tsumePracticeList?.[this.selectedDifficulty]?.userCount &&
        this.tsumePracticeList?.[this.selectedDifficulty]?.count
        ? parseInt(
            (this.tsumePracticeList[this.selectedDifficulty].userCount /
              this.tsumePracticeList[this.selectedDifficulty].count) *
              100
          )
        : 0;
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    device() {
      return this.$store.state.env.device;
    },
    tsumePracticeList() {
      return this.$store.getters['tsumeGame/practiceTsumeList'];
    },
    selectedPracticeList() {
      return this.tsumePracticeList?.[this.selectedDifficulty];
    },
    permission() {
      return this.$store.getters['env/permission'];
    },
  },
  watch: {
    tsumePracticeList: {
      handler() {
        if (this.tsumePracticeList != null) {
          this.isLoading = false;
          if (!this.selectedTsumeType) {
            this.selectedTsumeType = this.tsumePracticeList[0]?.types[0].type;
          }
        }
      },
      immediate: true,
    },
  },
  created() {
    if (!this.permission?.tsume) {
      this.isLoading = false;

      return;
    }
    this.$store.dispatch('tsumeGame/getPracticeTsumeList', this.currentPlan.id);
  },
  async mounted() {
    const _selectedDifficulty = JSON.parse(
      localStorage.getItem('selectedDifficulty')
    );
    if (_selectedDifficulty && _selectedDifficulty.currentPlanId) {
      if (_selectedDifficulty.currentPlanId === this.currentPlan.id) {
        this.selectedDifficulty = _selectedDifficulty.rank || 0;
        this.selectedTsumeType =
          _selectedDifficulty.type || this.tsumePracticeList[0].types[0].type;
      }
    }
  },
  methods: {
    async getTsumePractice(type) {
      if (this.$device.isMobile || type === 'startPracticeTsume') {
        BackEvent.clearEvents();
        this.$emit('start-practice-tsume');
        await this.$router.replace({
          name: 'practiceTsume',
          params: {
            rank: this.tsumePracticeList[this.selectedDifficulty].rank,
            type: type === 'startPracticeTsume' ? this.selectedTsumeType : type,
          },
        });
      } else {
        this.selectedTsumeType = type;
        const currentPlanId = this.currentPlan.id;
        localStorage.setItem(
          'selectedDifficulty',
          JSON.stringify({
            currentPlanId,
            rank: this.selectedDifficulty,
            type: this.selectedTsumeType,
          })
        );
      }
    },
    pickDifficulty(isLock, index) {
      BackEvent.popEvent();
      if (isLock) {
        this.$device.isMobile
          ? this.$refs['action-sheet'].closeSheet()
          : this.closeDifficulty();
        return;
      }
      this.selectedDifficulty = index;
      this.selectedTsumeType =
        this.tsumePracticeList[this.selectedDifficulty].types[0].type;
      const currentPlanId = this.currentPlan.id;
      localStorage.setItem(
        'selectedDifficulty',
        JSON.stringify({
          currentPlanId,
          rank: this.selectedDifficulty,
          type: this.selectedTsumeType,
        })
      );
      this.$device.isMobile
        ? this.$refs['action-sheet'].closeSheet()
        : this.closeDifficulty();
    },
    async closeDifficulty() {
      if (!this.$device.isMobile) {
        this.$refs.tsumePracticeDifficultyList.classList.add('remove');
        await delay(300);
      }
      BackEvent.popEvent();
      this.isDifficultyListShow = false;
    },
    showDifficulty() {
      BackEvent.addEvent(() => {
        BackEvent.popEvent();
        this.$refs['action-sheet'].closeSheet();
      });
      this.isDifficultyListShow = true;
    },
    onClickSidebar() {
      this.$store.commit('env/setIsSidebarShow', true);
      BackEvent.addEvent(() => {
        this.$store.commit('env/setIsSidebarShow', false);
      });
    },
    onClick2Web() {
      const homePageUrl = manifest.homePageUrl;

      Browser.open({
        url: homePageUrl + '/courses',
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap-tsume-practice-list {
  .wrapper {
    overflow: overlay;
    height: calc(100% - 56px);
    @media screen and (min-width: 768px) {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .wrap-tsume-practice-difficulty {
    background: white;
    .progress-rate {
      height: 20px;
      background: $grayscale-gray;
      border-radius: 10px;
    }
    .reach-rate {
      left: 0;
      height: 100%;
      background: $primary;
      width: 90%;
      border-radius: 10px;
    }
    .rate-percent {
      color: $font-grayscale-1;
      font-weight: bold;
    }
    .btn-change-tsume-difficulty {
      @media screen and (min-width: 768px) {
        width: 84px;
      }
    }
  }
  .tsume-practice-list {
    margin-bottom: 36px;
    @media screen and (min-width: 768px) {
      background: $bgsection;
      overflow: overlay;
      height: 100%;
      border-radius: 10px;
    }
    .condition {
      color: $font-grayscale-2;
    }
    .btn-sm {
      min-width: 65px;
    }
    .icon-Lock {
      font-size: 36px;
      color: $grayscale-20;
    }
  }
  &.jungo-wrap-tsume-practice-list {
    .wrap-tsume-practice-difficulty .reach-rate {
      background: $font-secondary;
    }
  }
}
.empty-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 240px;
  margin: 0 auto;
  ::v-deep {
    .empty {
      height: auto !important;
    }
    .icon-Kifu2,
    p {
      text-align: center;
      color: $font-grayscale-2;
    }
  }
}
.tsume-practice-difficulty-list {
  height: calc(100% - 56px);
  overflow: overlay;
  @media screen and (min-width: 768px) {
    position: absolute;
    right: 0;
    top: 0;
    width: 350px;
    z-index: 2023;
    background: $bgcontainer;
    height: 100%;
    transform: translateX(350px);
    &.active {
      animation: slideIn 0.3s 0.1s forwards;
    }
    &.remove {
      animation: slideOut 0.3s forwards;
    }
  }
  @keyframes slideIn {
    from {
      transform: translateX(350px);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes slideOut {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(350px);
    }
  }
  .condition {
    color: $font-grayscale-2;
  }
  .btn-sm {
    min-width: 65px;
  }
  .icon-Lock {
    font-size: 36px;
    color: $grayscale-20;
  }
  .icon-Colorful-correct {
    position: relative;
    font-size: 36px;
  }
  .icon-Colorful-correct-gray .path2,
  .icon-Colorful-correct .path2,
  .icon-Colorful-error .path2 {
    position: absolute;
    top: 0;
    left: 0;
  }
  .icon-Colorful-correct-gray .path2::before,
  .icon-Colorful-correct .path2::before,
  .icon-Colorful-error .path2::before {
    margin-left: 0;
  }
}
</style>
