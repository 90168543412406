<template>
  <b-card
    border-variant="white"
    class="tsume-difficulty-card card-shadow position-relative"
  >
    <div class="d-flex flex-nowrap justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <p class="difficulty-name m-0">
          【
          {{ `${difficultyName}` }}
          】
        </p>
      </div>
      <span v-if="isLock" class="icon-Lock mr-3"></span>
      <b-button
        v-else-if="buttonType === 'button'"
        variant="primary"
        size="sm"
        pill
        @click="onItemClick('restart')"
      >
        {{ $t('練習') }}
      </b-button>
      <span
        v-else-if="buttonType === 'pick'"
        class="icon-Colorful-correct mr-3"
      >
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
      <span
        v-else-if="buttonType === 'unpick'"
        class="icon-Colorful-correct-gray mr-3"
      >
        <span class="path1"></span>
        <span class="path2"></span>
      </span>
    </div>
  </b-card>
</template>

<script>
import rankList from '@/json/rank.json';

export default {
  props: {
    buttonType: {
      type: String,
      default: 'lock',
    },
    difficultyName: {
      type: String,
      default: '',
    },
    isLock: {
      type: Boolean,
      default: false,
    },
    rank: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      rankList,
    };
  },
};
</script>

<style lang="scss" scoped>
.tsume-difficulty-card {
  padding: 16px 16px 16px 24px;
  &:not(:last-of-type) {
    margin-bottom: 8px;
    @media screen and (min-width: 768px) {
      margin-bottom: 16px;
    }
  }
  @media screen and (min-width: 768px) {
    border: 4px solid white;
    padding: 20px 16px 20px 24px;
    &:hover {
      border: 4px solid #ced4da !important;
    }
  }
  .difficulty-name {
    font-size: 16px;
    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 34px;
      font-weight: 700;
    }
  }
}
.condition {
  color: $font-grayscale-2;
  @media screen and (min-width: 768px) {
    line-height: 18px;
    color: $font-normal;
  }
}
.btn-sm {
  min-width: 65px;
}
.icon-Lock {
  font-size: 36px;
  color: $grayscale-20;
}
.icon-Colorful-correct,
.icon-Colorful-correct-gray {
  font-size: 36px;
  position: relative;
}
.icon-Colorful-correct-gray .path2,
.icon-Colorful-correct .path2,
.icon-Colorful-error .path2 {
  position: absolute;
  top: 0;
  left: 0;
}
.icon-Colorful-correct-gray .path2::before,
.icon-Colorful-correct .path2::before,
.icon-Colorful-error .path2::before {
  margin-left: 0;
}
</style>
