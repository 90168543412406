<template>
  <b-card
    border-variant="white"
    class="tsume-type-card card-shadow position-relative cursor-pointer"
    :class="{'jungo-tsume-type-card': $isJungo}"
    @click.stop="!$device.isMobile ? onItemClick(tsumeType.type) : ''"
  >
    <div class="d-flex flex-nowrap justify-content-between align-items-center">
      <div class="d-flex flex-column">
        <p class="tsume-type text-16">
          {{ `${tsumeType.type}` }}
        </p>
        <p class="condition m-0">
          <span v-if="$isJungo">
            {{ tsumeType.userCount }} {{ $t('題') }}{{ $t('答對') }}
          </span>
          <span v-else>
            {{ $t('答對') }} {{ tsumeType.userCount }} {{ $t('題') }}
          </span>
          / {{ $t('共') }} {{ tsumeType.count }} {{ $t('題') }}
        </p>
      </div>
      <b-button
        v-if="$device.isMobile"
        class="btn-practice"
        variant="primary"
        :size="$isJungo ? 'md' : 'sm'"
        :pill="!$isJungo"
        @click.stop="onItemClick(tsumeType.type)"
      >
        {{ $t('練習') }}
      </b-button>
      <div v-else class="wrap-icon-correct">
        <span v-if="isSelected" class="icon-Colorful-correct">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
        <span v-else class="icon-Colorful-correct-gray">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </div>
    </div>
    <div v-if="$device.isMobile" class="progress-rate position-absolute">
      <div class="reach-rate position-absolute" :style="progressRate"></div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    tsumeType: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    progressRate() {
      return {
        width:
          parseInt((this.tsumeType.userCount / this.tsumeType.count) * 100) +
          '%',
      };
    },
  },
  methods: {
    onItemClick(type) {
      this.$emit('on-item-click', type);
    },
  },
};
</script>

<style lang="scss" scoped>
.tsume-type-card {
  padding: 16px 16px 16px 24px;
  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
  @media screen and (min-width: 768px) {
    border: 4px solid white;
    padding: 8px 32px 8px 20px;
    &:hover {
      border: 4px solid #ced4da !important;
    }
  }
  .tsume-type {
    margin-bottom: 4px;
    @media screen and (min-width: 768px) {
      line-height: 28px;
      margin-bottom: 6px;
    }
  }
  &.jungo-tsume-type-card {
    @media screen and (min-width: 768px) {
      &:hover {
        border: 4px solid $positive !important;
      }
    }
  }
}
.condition {
  color: $font-grayscale-2;
  font-size: 14px;
  @media screen and (min-width: 768px) {
    line-height: 20px;
    font-size: 12px;
  }
}
.btn-sm {
  min-width: 65px;
}
.progress-rate {
  height: 2px;
  background: $grayscale-gray;
  border-radius: 10px;
  bottom: 0;
  left: 2.7%;
  right: 2.7%;
}
.reach-rate {
  left: 0;
  height: 100%;
  background: $primary;
  width: 90%;
}
.wrap-icon-correct {
  height: 30px;
  @media screen and (min-width: 768px) {
    height: 36px;
  }
}
.icon-Colorful-correct,
.icon-Colorful-correct-gray {
  font-size: 30px;
  position: relative;
  @media screen and (min-width: 768px) {
    font-size: 36px;
  }
}
.icon-Colorful-correct-gray .path2,
.icon-Colorful-correct .path2,
.icon-Colorful-error .path2 {
  position: absolute;
  top: 0;
  left: 0;
}
.icon-Colorful-correct-gray .path2::before,
.icon-Colorful-correct .path2::before,
.icon-Colorful-error .path2::before {
  margin-left: 0;
}
</style>
